import "./style.css";
import * as dat from "lil-gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { gsap } from 'gsap'
import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'
import { FlakesTexture } from './FlakesTexture.js';
// import { Reflector } from './Reflector.js';
// import floorVertexShader from './shaders/floor/vertex-shader.glsl'
// import floorFragmentShader from './shaders/floor/fragment-shader.glsl'

const popupIframe = document.getElementById('popup-iframe');


// window.addEventListener('mousedown', () => {
//   console.log('clicked');
// })

// const home = document.querySelector('.mr-auto')
const home = document.querySelector('.mr-auto')
const projects = document.querySelector('.projects')
const about = document.querySelector('.about')
const contact = document.querySelector('.contact')

// Store the start time
const startTime = performance.now();

/**
 * Loaders
 */
 const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Cache loading-animation element
    const loadingAnimation = document.getElementById("loading-animation");

    // Calculate the actual duration of the loading
    const endTime = performance.now();
    const elapsedTime = (endTime - startTime) / 1000;

    // Use the remaining time to synchronize the animations
    const remainingTime = Math.max(0, 2 - elapsedTime);

    // Hide the CSS loading animation when the shader disappears
    gsap.to(loadingAnimation.style, {
      duration: remainingTime - 0.5,
      ease: Power0.easeNone,
      opacity: 0,
      onComplete: () => {
        loadingAnimation.style.display = "none";
      },
    });


    gsap.to(overlayMaterial.uniforms.uAlpha, {
      duration: 2,
      ease: Power0.easeNone,
      value: 0,
    });

    home.style.opacity = 1;
    projects.style.opacity = 1;
    about.style.opacity = 1;
    contact.style.opacity = 1;
  },
  // Progress
  () => {
    console.log('progress');
  }
)



// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);





/**
 * Base
 */
// Debug
// const debugObject = {};
// const gui = new dat.GUI({
//   width: 400,
// });


// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();


/**
 * Fog
 */
//  const fog = new THREE.Fog('#262837', 1, 15)
//  scene.fog = fog


/* Loading Animation Overlay */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  
  // Use uniforms to control outside of the shader
  uniforms: 
  {
    uAlpha: { value: 1 }
  },

  vertexShader: `
      void main()
      {
          gl_Position = vec4(position, 1.0);
      }
  `,

  fragmentShader: `
      uniform float uAlpha;
      void main()
      {
          gl_FragColor = vec4(0.13, 0.12, 0.16, uAlpha);
      }
  `
})

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)



/**
 * Textures
 */
const bakedTexture = textureLoader.load("13_6_3.jpg");
const aboutBoardTexture = textureLoader.load("aboutMesh.png");
const backBtnTexture = textureLoader.load("backBtn.png");
const contactBoardTexture = textureLoader.load("contact-1.png");
const projectListTexture = textureLoader.load("projects-list-9.png");

const larcTexture = textureLoader.load("larc-test-7.png");
const silverTexture = textureLoader.load("silver-test-5.png");
const cinemaPosterTexture = textureLoader.load("cinema-test-3.png");
const mcmTexture = textureLoader.load("mcm-test-4.png");

// const streetTexture = textureLoader.load("asphalt-1.jpeg");
bakedTexture.flipY = false;
projectListTexture.flipY = true;

bakedTexture.encoding = THREE.sRGBEncoding;
aboutBoardTexture.encoding = THREE.sRGBEncoding;
contactBoardTexture.encoding = THREE.sRGBEncoding;
projectListTexture.encoding = THREE.sRGBEncoding;
larcTexture.encoding = THREE.sRGBEncoding;
silverTexture.encoding = THREE.sRGBEncoding;
cinemaPosterTexture.encoding = THREE.sRGBEncoding;
mcmTexture.encoding = THREE.sRGBEncoding;


/* Floor 2  */

// Directional Light
const directionalLight = new THREE.DirectionalLight(0xffffff, 0.1);
directionalLight.position.set(0, 5, -4);
scene.add(directionalLight);

// Texture
// https://threejs.org/docs/#api/en/materials/MeshPhysicalMaterial
let planeTexture = new THREE.CanvasTexture(new FlakesTexture());
planeTexture.wrapS = THREE.RepeatWrapping;
planeTexture.wrapT = THREE.RepeatWrapping;
planeTexture.repeat.x = 180;
planeTexture.repeat.y = 180;

const planeMaterial = {
  clearcoat: 0.3,
  clearcoatRoughness: 1.0,
  metalness: 0.6,
  roughness:0.2,
  reflectivity: 0.8,
  // color: 0x050202,
  // color: 0xf3deb6,
  color: 0xf0eadb,
  normalMap: planeTexture,
  normalScale: new THREE.Vector2(0.07, 0.07),
  
}

const planeGeometry = new THREE.PlaneGeometry(100, 100)
const planeFlackMaterial = new THREE.MeshPhysicalMaterial(planeMaterial)
const planeMesh = new THREE.Mesh(planeGeometry, planeFlackMaterial)
planeMesh.position.set(0, -0.9, 0)
planeMesh.rotation.x = Math.PI * -0.5
scene.add(planeMesh)




// /* Floor 3: Mirror */
// const mirrorOptions = {
//   clipBias: 0.9, // default 0, limits reflection
//   textureWidth: window.innerWidth * window.devicePixelRatio,
//   textureHeight: window.innerHeight * window.devicePixelRatio,
//   color: 0x7f7f7f, // defalut: 7f7f7f
//   multisample: 1, // default: 4, type of antialiasing( improve img quality)
//   opacity: 0.1
// }

// const mirrorGeometry = new THREE.PlaneGeometry(10, 10);
// const mirror = new Reflector( mirrorGeometry, mirrorOptions);

// // mirror.position.set(0, 1, -10)
// // mirror.rotation.x = Math.PI * -0.5
// // mirror.position.set(0, -0.9, 0)

// // // mirror.rotateX( -Math.PI / 2);
// // // mirror.rotation.y = Math.PI * 0.5

// // scene.add(mirror)




/**
 * Materials 정의하기
 */


// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture, side: THREE.DoubleSide });


// Neon Light Material
const neonRedMaterial = new THREE.MeshBasicMaterial({ color: 0xffffe5 }); // 빛을 직접적으로 넣는게 아니라 빛 색을 띄는 material을 입히는거구나!

const neonWhiteMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide });

const neonStarMaterial = new THREE.MeshBasicMaterial({ color: 0xff7a00 });

const panel1Material = new THREE.MeshBasicMaterial({ color: 0xffffff})

const redMaterial = new THREE.MeshBasicMaterial({ color: 0x1f1616})

const pondMaterial = new THREE.ShaderMaterial({
  uniforms:
  {
      uTime: { value: 0 }
  },
  vertexShader: portalVertexShader,
  fragmentShader: portalFragmentShader
})


/**
 * Model
 */
gltfLoader.load("14-5_4.glb", (gltf) => {
  // gltf.scene.traverse((child) =>   // 오브젝트를 하나로 merge 했으므로 더이상 traverse 할 필요가 없다.

  // {
  //     child.material = bakedMaterial
  //     console.log(child)
  // })
  //** Mesh 찾기 **/
  // console.log(gltf.scene);

  const bakedMesh = gltf.scene.children.find((child) => child.name === "baked");

  const introMesh = gltf.scene.children.find((child) => child.name === "intro")

  const neonRedMesh = gltf.scene.children.find(
    (child) => child.name === "neon-red"
  );
  const neonWhiteMesh = gltf.scene.children.find(
    (child) => child.name === "neon-white"
  );

  const neonStarMesh = gltf.scene.children.find(
    (child) => child.name === "star"
  );

const panel1Mesh = gltf.scene.children.find(
  (child) => child.name === "panel-1"
);

const panel2Mesh = gltf.scene.children.find(
  (child) => child.name === "panel-2"
);

const pondMesh = gltf.scene.children.find(
  (child) => child.name === "pond-panel"
);

// console.log([pondMesh])

  //** 찾은 Mesh.material에 텍스쳐 입히기 **/
  bakedMesh.material = bakedMaterial;
  introMesh.material = neonWhiteMaterial;
  introMesh.position.y = - 0.69

  neonRedMesh.material = neonRedMaterial;
  neonWhiteMesh.material = neonWhiteMaterial;
  neonStarMesh.material = neonStarMaterial;
  panel1Mesh.material= panel1Material;

  panel2Mesh.material= panel1Material;

  pondMesh.material = pondMaterial;
  // pondMesh.material = planeFlackMaterial;

  scene.add(gltf.scene);
});





/**
 * Clickable Meshes
 */
// 1. aboutMesh
  const aboutGeometry = new THREE.PlaneGeometry( 0.532, 0.61 );
  const aboutMaterial = new THREE.MeshBasicMaterial( {map: aboutBoardTexture} );
  const aboutMesh = new THREE.Mesh( aboutGeometry, aboutMaterial );
  aboutMesh.position.set(-0.195, -0.1, -0.08)
  aboutMaterial.transparent = false
  scene.add( aboutMesh );
  
  // 2. aboutMesh Button : click to learn more
  const aboutBtnGeometry = new THREE.PlaneGeometry( 0.45, 0.06 );
  const aboutBtnMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const aboutBtnMesh = new THREE.Mesh( aboutBtnGeometry, aboutBtnMaterial );
  aboutBtnMesh.position.set(-0.19, -0.33, -0.07)
  aboutBtnMaterial.transparent = true
  aboutBtnMaterial.opacity = 0
  scene.add( aboutBtnMesh );


// 3. About Back button 
const aboutBackBtnGeometry = new THREE.PlaneGeometry( 0.08, 0.03 );
const aboutBackBtnMaterial = new THREE.MeshBasicMaterial( {map: backBtnTexture} );

// const aboutBackBtnMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );

const aboutBackBtnMesh = new THREE.Mesh( aboutBackBtnGeometry, aboutBackBtnMaterial );
aboutBackBtnMesh.position.set(-0.03, 0.15, -0.07)
aboutBackBtnMaterial.transparent = false
scene.add( aboutBackBtnMesh );



  // 4. aboutMesh Sign
  const aboutSignGeometry = new THREE.PlaneGeometry( 0.685, 0.22 );
  const aboutSignMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00/* , side: THREE.DoubleSide */}  );
  const aboutSignMesh = new THREE.Mesh( aboutSignGeometry, aboutSignMaterial );
  aboutSignMesh.position.set(-0.195, 0.38, -0.08)
  aboutSignMaterial.transparent = true
  aboutSignMaterial.opacity = 0
  scene.add( aboutSignMesh );


  // 5. contactMesh
  const contactGeometry = new THREE.PlaneGeometry( 0.532, 0.61 );
  const contactMaterial = new THREE.MeshBasicMaterial( {map: contactBoardTexture} );
  const contactMesh = new THREE.Mesh( contactGeometry, contactMaterial );
  contactMesh.position.set(1.8, -0.1, -0.089)
  contactMaterial.transparent = false
  contactMaterial.opacity = 0.1
  scene.add( contactMesh );

  // 6. Contact Back button 
const contactBackBtnGeometry = new THREE.PlaneGeometry( 0.08, 0.03 );
const contactBackBtnMaterial = new THREE.MeshBasicMaterial( {map: backBtnTexture} );
const contactBackBtnMesh = new THREE.Mesh( contactBackBtnGeometry, contactBackBtnMaterial );
contactBackBtnMesh.position.set(1.97, 0.15, -0.07)
contactBackBtnMaterial.transparent = false
scene.add( contactBackBtnMesh );


  // 7. projectDirMesh
  const projectDirGeometry = new THREE.PlaneGeometry( 0.39, 0.116 );
  const projectDirMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const projectDirMesh = new THREE.Mesh( projectDirGeometry, projectDirMaterial );
  projectDirMesh.position.set(0.3, 0.05, 1.7)
  projectDirMaterial.transparent = true
  projectDirMaterial.opacity = 0
  scene.add(projectDirMesh);

  // 8. aboutDirMesh
  const aboutDirGeometry = new THREE.PlaneGeometry( 0.39, 0.116 );
  const aboutDirMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const aboutDirMesh = new THREE.Mesh( aboutDirGeometry, aboutDirMaterial );
  aboutDirMesh.position.set(0.297, -0.125, 1.71)
  aboutDirMaterial.transparent = true
  aboutDirMaterial.opacity = 0
  scene.add(aboutDirMesh);  

  // 9. contactDirMesh
  const contactDirGeometry = new THREE.PlaneGeometry( 0.39, 0.116 );
  const contactDirMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const contactDirMesh = new THREE.Mesh( contactDirGeometry, contactDirMaterial );
  contactDirMesh.position.set(0.297, -0.3, 1.71)
  contactDirMaterial.transparent = true
  contactDirMaterial.opacity = 0
  scene.add(contactDirMesh);    

  // 10. Linkedin Btn Mesh
  const linkedInBtnGeometry = new THREE.PlaneGeometry( 0.06, 0.06 );
  const linkedInBtnMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const linkedInBtnMesh = new THREE.Mesh( linkedInBtnGeometry, linkedInBtnMaterial );
  linkedInBtnMesh.position.set(1.72, -0.074, -0.07)
  linkedInBtnMaterial.transparent = true
  linkedInBtnMaterial.opacity = 0

  scene.add( linkedInBtnMesh );

  // 11. emailBtnMesh
  const emailBtnGeometry = new THREE.PlaneGeometry( 0.06, 0.06 );
  const emailBtnMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const emailBtnMesh = new THREE.Mesh( emailBtnGeometry, emailBtnMaterial );
  emailBtnMesh.position.set(1.798, -0.074, -0.07)
  emailBtnMaterial.transparent = true
  emailBtnMaterial.opacity = 0
  scene.add( emailBtnMesh );


// 12. project List Mesh
// const projectListGeometry = new THREE.PlaneGeometry( 0.883, 0.536 );
const projectListGeometry = new THREE.PlaneGeometry( 1.017, 0.61734 );
const projectListMaterial = new THREE.MeshBasicMaterial( {map: projectListTexture, /* side: THREE.DoubleSide  */} );
const projectListMesh = new THREE.Mesh( projectListGeometry, projectListMaterial );
projectListMesh.position.set(0.78, 0.97, -0.63)
projectListMaterial.transparent = false
projectListMesh.rotation.y = Math.PI * -1
projectListMaterial.opacity = 0
scene.add( projectListMesh );



// 13. projectListLarc
const projectListLarcGeometry = new THREE.PlaneGeometry( 0.42, 0.062 );
const projectListLarcMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, /* side: THREE.DoubleSide  */} );
const projectListLarcMesh = new THREE.Mesh( projectListLarcGeometry, projectListLarcMaterial );
projectListLarcMesh.position.set(0.78, 1.092, -0.64)
projectListLarcMaterial.transparent = true
// projectListLarcMesh.rotation.x = Math.PI * -1
projectListLarcMesh.rotation.y = Math.PI * -1
projectListLarcMaterial.opacity = 0.5
scene.add( projectListLarcMesh );


// 14. projectListSilver
const projectListSilverGeometry = new THREE.PlaneGeometry( 0.42, 0.062 );
const projectListSilverMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, /* side: THREE.DoubleSide */ } );
const projectListSilverMesh = new THREE.Mesh( projectListSilverGeometry, projectListSilverMaterial );
projectListSilverMesh.position.set(0.78, 0.979, -0.64)
projectListSilverMaterial.transparent = true
// projectListSilverMesh.rotation.x = Math.PI * -1
projectListSilverMesh.rotation.y = Math.PI * -1
projectListSilverMaterial.opacity = 0.5
scene.add( projectListSilverMesh );


// 15. projectListCinema
const projectListCinemaGeometry = new THREE.PlaneGeometry( 0.55, 0.062 );
const projectListCinemaMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, /* side: THREE.DoubleSide */ } );
const projectListCinemaMesh = new THREE.Mesh( projectListCinemaGeometry, projectListCinemaMaterial );
projectListCinemaMesh.position.set(0.78, 0.866, -0.64)
projectListCinemaMaterial.transparent = true
// projectListCinemaMesh.rotation.x = Math.PI * -1
projectListCinemaMesh.rotation.y = Math.PI * -1
projectListCinemaMaterial.opacity = 0.5
scene.add( projectListCinemaMesh );


// 16. projectListMcm
const projectListMcmGeometry = new THREE.PlaneGeometry( 0.64, 0.062 );
const projectListMcmMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00, /* side: THREE.DoubleSide */ } );
const projectListMcmMesh = new THREE.Mesh( projectListMcmGeometry, projectListMcmMaterial );
projectListMcmMesh.position.set(0.78, 0.752, -0.64)
projectListMcmMaterial.transparent = true
// projectListMcmMesh.rotation.x = Math.PI * -1
projectListMcmMesh.rotation.y = Math.PI * -1
projectListMcmMaterial.opacity = 0.5
scene.add( projectListMcmMesh );

// testMesh
  const testGeometry = new THREE.PlaneGeometry( 0.43, 0.21 );
  const testMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00} );
  const testMesh = new THREE.Mesh( testGeometry, testMaterial );
  testMesh.position.set(0.2, -0.1, 0)
  testMaterial.transparent = true
  testMaterial.opacity = 0
  scene.add( testMesh );


/* Poster displays */

// 17. larcMesh
// const projectListGeometry = new THREE.PlaneGeometry( 0.883, 0.536 );
const larcGeometry = new THREE.PlaneGeometry( 0.371, 0.659 );
const larcMaterial = new THREE.MeshBasicMaterial({ map: larcTexture });

// const larcMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00 /* side: THREE.DoubleSide  */} );
const larcMesh = new THREE.Mesh( larcGeometry, larcMaterial );
larcMesh.position.set(1.125, -0.07, -0.65) //1.79, -0.07, -0.65
larcMaterial.transparent = false
larcMesh.rotation.y = Math.PI * -1
larcMaterial.opacity = 0
scene.add( larcMesh );


// 18. larcBtnMesh
const larcBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const larcBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
// const larcMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00 /* side: THREE.DoubleSide  */} );
const larcBtnMesh = new THREE.Mesh( larcBtnGeometry, larcBtnMaterial );
larcBtnMesh.position.set(1.125, -0.292, -0.7) //1.79, -0.292, -0.7
larcBtnMaterial.transparent = true
larcBtnMesh.rotation.y = Math.PI * -1
larcBtnMaterial.opacity = 0
scene.add( larcBtnMesh );

// 19. larcBackBtnMesh
const larcBackBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const larcBackBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
// const larcMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00 /* side: THREE.DoubleSide  */} );
const larcBackBtnMesh = new THREE.Mesh( larcBackBtnGeometry, larcBackBtnMaterial );
larcBackBtnMesh.position.set(1.79, -0.34, -0.7)
larcBackBtnMaterial.transparent = true
larcBackBtnMesh.rotation.y = Math.PI * -1
larcBackBtnMaterial.opacity = 0
scene.add( larcBackBtnMesh );


// 20. silverMesh
const silverGeometry = new THREE.PlaneGeometry( 0.371, 0.659 );
const silverMaterial = new THREE.MeshBasicMaterial({ map: silverTexture });
const silverMesh = new THREE.Mesh( silverGeometry, silverMaterial );
silverMesh.position.set(1.79, -0.07, -0.65) //1.125, -0.07, -0.65
silverMaterial.transparent = false
silverMesh.rotation.y = Math.PI * -1
silverMaterial.opacity = 0
scene.add( silverMesh );


// 21. silverBtnMesh
const silverBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const silverBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
const silverBtnMesh = new THREE.Mesh( silverBtnGeometry, silverBtnMaterial );
silverBtnMesh.position.set(1.79, -0.292, -0.7) //1.125, -0.292, -0.7
silverBtnMaterial.transparent = true
silverBtnMesh.rotation.y = Math.PI * -1
silverBtnMaterial.opacity = 0
scene.add( silverBtnMesh );

// 22. silverBackBtnMesh
const silverBackBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const silverBackBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
// const larcMaterial = new THREE.MeshBasicMaterial( {color: 0xffff00 /* side: THREE.DoubleSide  */} );
const silverBackBtnMesh = new THREE.Mesh( silverBackBtnGeometry, silverBackBtnMaterial );
silverBackBtnMesh.position.set(1.125, -0.34, -0.7)
silverBackBtnMaterial.transparent = true
silverBackBtnMesh.rotation.y = Math.PI * -1
silverBackBtnMaterial.opacity = 0
scene.add( silverBackBtnMesh );

// 23. cinemaPosterMesh
const cinemaPosterGeometry = new THREE.PlaneGeometry( 0.371, 0.659 );
const cinemaPosterMaterial = new THREE.MeshBasicMaterial({ map: cinemaPosterTexture });
const cinemaPosterMesh = new THREE.Mesh( cinemaPosterGeometry, cinemaPosterMaterial );
cinemaPosterMesh.position.set(0.46, -0.07, -0.65)
cinemaPosterMaterial.transparent = false
cinemaPosterMesh.rotation.y = Math.PI * -1
cinemaPosterMaterial.opacity = 0
scene.add( cinemaPosterMesh );

// 24. cinemaPosterBtnMesh
const cinemaPosterBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const cinemaPosterBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
const cinemaPosterBtnMesh = new THREE.Mesh( cinemaPosterBtnGeometry, cinemaPosterBtnMaterial );
cinemaPosterBtnMesh.position.set(0.47, -0.292, -0.7)
cinemaPosterBtnMaterial.transparent = true
cinemaPosterBtnMesh.rotation.y = Math.PI * -1
cinemaPosterBtnMaterial.opacity = 0
scene.add( cinemaPosterBtnMesh );


// 25. cinemaPosterBackBtnMesh
const cinemaPosterBackBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const cinemaPosterBackBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
const cinemaPosterBackBtnMesh = new THREE.Mesh( cinemaPosterBackBtnGeometry, cinemaPosterBackBtnMaterial );
cinemaPosterBackBtnMesh.position.set(0.47, -0.34, -0.7)
cinemaPosterBackBtnMaterial.transparent = true
cinemaPosterBackBtnMesh.rotation.y = Math.PI * -1
cinemaPosterBackBtnMaterial.opacity = 0
scene.add( cinemaPosterBackBtnMesh );


// 26. mcmMesh
const mcmGeometry = new THREE.PlaneGeometry( 0.371, 0.659 );
const mcmMaterial = new THREE.MeshBasicMaterial({ map: mcmTexture });
const mcmMesh = new THREE.Mesh( mcmGeometry, mcmMaterial );
mcmMesh.position.set(-0.18, -0.07, -0.65)
mcmMaterial.transparent = false
mcmMesh.rotation.y = Math.PI * -1
mcmMaterial.opacity = 0
scene.add( mcmMesh );


// 27. mcmBackBtn
const mcmBackBtnGeometry = new THREE.PlaneGeometry( 0.3, 0.035 );
const mcmBackBtnMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
const mcmBackBtnMesh = new THREE.Mesh( mcmBackBtnGeometry, mcmBackBtnMaterial );
mcmBackBtnMesh.position.set(-0.18, -0.34, -0.7)
mcmBackBtnMaterial.transparent = true
mcmBackBtnMesh.rotation.y = Math.PI * -1
mcmBackBtnMaterial.opacity = 0
scene.add( mcmBackBtnMesh );


/* meshesToTest */
  const meshesToTest = [contactMesh, aboutMesh, aboutBtnMesh, aboutSignMesh, testMesh,
    projectDirMesh, aboutBackBtnMesh, contactBackBtnMesh, linkedInBtnMesh,
    emailBtnMesh, aboutDirMesh, contactDirMesh, projectListMesh, 
    projectListLarcMesh, projectListSilverMesh, projectListCinemaMesh, projectListMcmMesh,
    larcMesh, larcBtnMesh, larcBackBtnMesh,silverMesh, 
    silverBtnMesh, silverBackBtnMesh, cinemaPosterMesh, cinemaPosterBtnMesh, 
    cinemaPosterBackBtnMesh, mcmMesh,mcmBackBtnMesh,
  ]

  const raycaster = new THREE.Raycaster() // Don't create it this on tick(). Create once here, and re-use it multiple times.



/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};



window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});


/**
 * Mouse: Convert pixel to normalized value (0 to 1)
 */
const mouse = new THREE.Vector2()
window.addEventListener('mousemove', (_event) => 
{
  mouse.x = _event.clientX / sizes.width * 2 - 1
  mouse.y = - (_event.clientY / sizes.height) * 2 + 1
})

const cover = document.querySelector('.cover')

/* Mouse hover -> pointer */

const htmlBody = document.querySelector('body')



window.addEventListener('mousedown', () => {
  htmlBody.classList.remove('cursor')
})


window.addEventListener('mousemove', (event) => {
  if(currentIntersect)
  {
    switch(currentIntersect)
    {
      case aboutBackBtnMesh:
      case contactBackBtnMesh:
      case linkedInBtnMesh:
      case aboutBtnMesh:
      case emailBtnMesh:
      case projectListLarcMesh:
      case projectListSilverMesh:
      case projectListCinemaMesh:
      case projectListMcmMesh:
      case larcBtnMesh:
      case silverBtnMesh:
      case larcBackBtnMesh:  
      case silverBackBtnMesh:
      case cinemaPosterBtnMesh:
      case cinemaPosterBackBtnMesh:
      case mcmBackBtnMesh:
        htmlBody.classList.add('cursor');
      break;

      default:
        htmlBody.classList.remove('cursor')
        break;
    }
  }
})


/**
 * Mesh click event listener
 */

// Add this variable at the beginning of your script
let linkOpened = false;

 function handleInteraction(event) {
  // Update the mouse coordinates for touch events
  if (event.type === "touchstart" || event.type === "touchmove" || event.type === "touchend") {
    mouse.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
  }

    // If a link was opened, prevent the rest of the code from executing
    if (linkOpened) {
      linkOpened = false;
      return;
    }

    switch(currentIntersect){

// aboutBackBtn
case (aboutBackBtnMesh):  
gsap.to(camera.position, {
  x: 0.05,
  y: 2,
  z: 8,
  duration: 1,
})

gsap.to(controls.target, {
x: 0.05,
y: 0,
z: 0,
duration: 1,
})
break

//aboutMesh, aboutSignMesh, aboutDirMesh 
case (aboutMesh):
  case (aboutSignMesh):  
  case (aboutDirMesh): 
      gsap.to(camera.position, {
        x: -0.15,
        y: -1,
        z: 0.1,
        duration: 1,
    })
  
    gsap.to(controls.target, {
      x: -0.15,
      y: -0.1,
      z: -0.1,
      duration: 1,
    })
      break

//aboutBtnMesh     
          case aboutBtnMesh:
            window.open("https://stellaturner.io/about/", "_blank");

            linkOpened = true;
            setTimeout(() => {
              currentIntersect = null;
            }, 100);

            break   

//contactMesh
        case (contactDirMesh):
        case (contactMesh):
          gsap.to(camera.position, {
            x: 1.7,
            y: -1,
            z: 0.1,
            // x: 1.7,
            // y: -0.05,
            // z: 1.3,
            duration: 1,
        })
      
        gsap.to(controls.target, {
          x: 1.7,
          y: -0.1,
          z: -0.1,
          // x: 1.7,
          // y: -0.05,
          // z: 0,
          duration: 1,
        })            
        break

// contactBackBtnMesh
case (contactBackBtnMesh):  
gsap.to(camera.position, {
  x: 0.05,
  y: 2,
  z: 8,
  duration: 1,
})

gsap.to(controls.target, {
x: 0.05,
y: 0,
z: 0,
duration: 1,
})
break


// linkedInBtnMesh
case linkedInBtnMesh:
  window.open("https://www.linkedin.com/in/stella-turner/", "popup");
  linkOpened = true;

break  


// emailBtnMesh
case emailBtnMesh:
  window.open("mailto:contact@stellaturner.io", "popup");
  linkOpened = true;

break  

// larcBtnMesh
case larcBtnMesh:
  window.open("https://stellaturner.io/larc-method/", "_blank");
  linkOpened = true;

break  

// silverBtnMesh
case silverBtnMesh:
  window.open("https://stellaturner.io/silver-health/", "_blank");
  linkOpened = true;

break  

// cinemaPosterBtnMesh
case cinemaPosterBtnMesh:
  window.open("https://stellaturner.io/stella-cinema", "_blank");
  linkOpened = true;

break  

//projectDirMesh
        case projectDirMesh:
        case larcBackBtnMesh:  
        case silverBackBtnMesh:
        case cinemaPosterBackBtnMesh:
        case mcmBackBtnMesh:
            gsap.to(camera.position, {
              x: 0.8,
              y: 0.5,
              z: -3.5,
              duration: 0.8,
            })

            gsap.to(controls.target, {
              x: 0.8,
              y: 0.5,
              z: -1,
              duration: 0.8,
            })
              break    

// projectListMesh
case projectListMesh:
  gsap.to(camera.position, {
    x: 0.8,
    y: 0.85,
    z: -2,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: 0.8,
    y: 0.85,
    z: -1,
    duration: 0.8,
  })
break    

// projectListLarcMesh
case projectListLarcMesh:
case silverMesh:
  gsap.to(camera.position, {
    x: 1.8,
    y: -0.25,
    z: -1.9,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: 1.8,
    y: -0.25,
    z: 0,
    duration: 0.8,
  })
break  


// projectListSilverMesh
case projectListSilverMesh:
case larcMesh:  
  gsap.to(camera.position, {
    x: 1.1,
    y: -0.25,
    z: -1.9,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: 1.1,
    y: -0.25,
    z: 0,
    duration: 0.8,
  })
break  

// projectListCinemaMesh
case projectListCinemaMesh:
case cinemaPosterMesh:  
  gsap.to(camera.position, {
    x: 0.44,
    y: -0.25,
    z: -1.9,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: 0.44,
    y: -0.25,
    z: 0,
    duration: 0.8,
  })
break  

// projectListMcmMesh
case projectListMcmMesh:
case mcmMesh:
  gsap.to(camera.position, {
    x: -0.22,
    y: -0.25,
    z: -1.9,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: -0.22,
    y: -0.25,
    z: 0,
    duration: 0.8,
  })
break  
  }
}
// Mesh click event listener for desktop
window.addEventListener("click", handleInteraction);

// Mesh touch event listener for mobile
window.addEventListener("touchstart", handleInteraction);
window.addEventListener("touchend", (event) => {
  // Reset the mouse coordinates when the touch ends
  mouse.x = -10000;
  mouse.y = -10000;
});


/* NAV */

// Nav - Home
home.addEventListener('click', () => {
  gsap.to(camera.position, {
    x: 0.035,
    y: 2,
    z: 8,
    duration: 1,
  })
  
  gsap.to(controls.target, {
  x: 0.035,
  y: 0,
  z: 0,
  duration: 1,
  })
})

// Nav - Project
projects.addEventListener('click', () => {
  gsap.to(camera.position, {
    x: 0.8,
    y: 0.1,
    z: -4,
    duration: 0.8,
  })

  gsap.to(controls.target, {
    x: 0.8,
    y: 0.1,
    z: 0,
    duration: 0.8,
  })
})


// Nav - About
about.addEventListener('click', () => {
  gsap.to(camera.position, {
    x: -0.15,
    y: -1,
    z: 0.1,
    duration: 1,
})


gsap.to(controls.target, {
  x: -0.15,
  y: -0.1,
  z: -0.1,
  duration: 1,
})
})

// Nav - Contact
contact.addEventListener('click', () => {
  gsap.to(camera.position, {
    x: 1.8,
    y: -1,
    z: 0.1,
    // x: 1.7,
    // y: -0.05,
    // z: 1.3,
    duration: 1,
})

gsap.to(controls.target, {
  x: 1.8,
  y: -0.1,
  z: -0.1,
  // x: 1.7,
  // y: -0.05,
  // z: 0,
  duration: 1,
}) 
})


/**
 * Camera + if the screen size is smaller than 480px, change camera position to make it responsive.
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  100
);

camera.position.x = 0;
camera.position.y = 2;
camera.position.z = 8;


if (sizes.width < 480 ) {
  console.log('test');
  camera.position.x = 0;
  camera.position.y = 2;
  camera.position.z = 8;
}

scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.minPolarAngle = 0;
controls.maxPolarAngle = Math.PI * 0.45;
controls.minDistance = 0;
controls.maxDistance = 8;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  alpha: true,
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1.25;

// After instancing the renderer, add a clearColor property to the debugObject object
// debugObject.clearColor = "#201f28";

// Link the color to our scene
renderer.setClearColor("#201f28");

// gui.addColor(debugObject, "clearColor").onChange(() => {
//   renderer.setClearColor(debugObject.clearColor);
// });

/**
 * Animate
 */
 const clock = new THREE.Clock();

 let currentIntersect = null;
 
 const tick = () => {
   const elapsedTime = clock.getElapsedTime();
 
   // Raycaster
   raycaster.setFromCamera(mouse, camera);
 
   // const meshesToTest = [aboutMesh, contactMesh]
   const intersects = raycaster.intersectObjects(meshesToTest);
   //console.log(intersects.length);
 
   if (intersects.length) {
     currentIntersect = intersects[0].object;
   } else {
     currentIntersect = null;
   }
 
   pondMaterial.uniforms.uTime.value = elapsedTime * 1.5;
 
   // Update controls. Move the camera first, then move the points.
   controls.update();
 
   // Render
   renderer.render(scene, camera);
 
   // Call tick again on the next frame
   window.requestAnimationFrame(tick);
 };
 
 tick();